var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"mb-4",staticStyle:{"border":"none","visibility":"collapse"}},[(
                    this.objData != null && this.objData.trigger != null &&
                     this.objData.trigger.isScheduleEnabled && 
                     this.objData.trigger.scheduleConf != null &&
                     this.objData.trigger.scheduleConf.sourceType != null && 
                         (this.objData.trigger.scheduleConf.sourceType === 'sql' || 
                          this.objData.trigger.scheduleConf.sourceType === 'file'))?_c('v-btn',{staticClass:"mr-2 mb-4",attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.testConnection('source')}}},[_vm._v(" test source connection")]):_vm._e(),(
                    this.objData != null && 
                    this.objData.destinations != null && 
                    this.objData.destinations.length > 0 && 
                    this.objData.destinations[0].ref!= null 
                    && (this.currentDestType === 'Rdbms' || this.currentDestType === 'File')
                  )?_c('v-btn',{staticClass:"mb-4",attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.testConnection('destination')}}},[_vm._v(" test destination connection")]):_vm._e()],1),_c('v-tabs',{key:_vm.destinationFormsKey,on:{"change":_vm.tabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{key:0},[_vm._v("Flow")]),_c('v-tab',{key:1},[_vm._v("Destination")]),_c('v-tab',{key:2},[_vm._v("Options")]),_c('v-tab',{key:3},[_vm._v("Pipeline")]),_c('v-tab',{key:4},[_vm._v("Raw data")]),(_vm.debug)?_c('v-tab',{key:5},[_vm._v("Applyed schema")]):_vm._e(),(_vm.debug)?_c('v-tab',{key:6},[_vm._v("Applyed UI")]):_vm._e(),_c('v-tab-item',{key:0},[_c('json-forms',{ref:"formdbase",attrs:{"data":_vm.objData,"schema":_vm.schemaFlow,"uischema":_vm.uischemaFlow,"renderers":_vm.renderers,"validationMode":'ValidateAndShow',"ajv":_vm.ajv},on:{"change":_vm.onChangeSourceForm}})],1),_c('v-tab-item',{key:1},[_c('json-forms',{ref:"formdest",attrs:{"data":_vm.objData,"schema":_vm.schemaFlow,"uischema":_vm.uischemaFlowDestination,"renderers":_vm.renderers,"validationMode":'validateAndShow',"ajv":_vm.ajv},on:{"change":_vm.onChangeDestinationForm}})],1),_c('v-tab-item',{key:2},[_c('json-forms',{ref:"formopt",attrs:{"data":_vm.objData,"schema":_vm.schemaFlow,"uischema":_vm.uischemaFlowOptions,"renderers":_vm.renderers,"validationMode":'validateAndShow',"ajv":_vm.ajv},on:{"change":_vm.onChangeOptionsForm}})],1),_c('v-tab-item',{key:3},[_c('json-forms',{ref:"formpipeline",attrs:{"data":_vm.objData,"schema":_vm.schemaFlow,"uischema":_vm.uischemaFlowPipeline,"renderers":_vm.renderers,"validationMode":'validateAndShow',"ajv":_vm.ajv},on:{"change":_vm.onChangePipelineForm}})],1),_c('v-tab-item',{key:4},[_c('vue-monaco-editor',{attrs:{"height":"600px","language":"json","loading":"false"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }