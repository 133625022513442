import Vue from "vue";
import VueRouter from "vue-router";

import Queue from "../components/queue/queue.vue";
import Destinations from "../components/destinations/destinations.vue";
import DestinationsEdit from "../components/destinations/destinationsEdit.vue";
import Flows from "../components/flows/flows.vue";
import FlowsView from "../components/flows/flowView.vue";
import Settings from "../components/Settings.vue";
import Logs from "../components/logs/logs.vue";
import Dashboard from "../components/Dashboard.vue";
import Forms from "../components/shared/FormsEditor.vue";
import Login from "../components/auth/login";
import User from "../components/auth/User";
import userService from "../services/user";
import Redirect from "../components/auth/Redirect.vue";
import Actions from "../components/lowcode/Actions";
import ActionDesign from "../components/lowcode/ActionDesign";
import BufferStat from "../components/BufferStat.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    name: "flows",
    path: "/flows",
    components: {
      default: Flows,
    },
    meta: {
      title: "Flows",
    },
    label: "Flows",
    closable: false,
    menu: true,
  },
  {
    name: "flows.view",
    path: "/flows/:id",
    component: FlowsView,
    meta: {
      title: "Flow View",
    },
    label: "Flow View",
    closable: false,
    menu: true,
  },
  {
    name: "destinations",
    path: "/destinations",
    components: {
      default: Destinations,
    },
    meta: {
      title: "Destinations",
    },
    label: "Destinations",
    closable: false,
    menu: true,
  },
  {
    name: "queue",
    path: "/queue",
    components: {
      default: Queue,
    },
    meta: {
      title: "Queue",
    },
    label: "Queue",
    closable: false,
    menu: true,
  },
  {
    name: "logs",
    path: "/logs",
    components: {
      default: Logs,
    },
    meta: {
      title: "Logs",
    },
    label: "Logs",
    closable: false,
    menu: true,
  },
  {
    name: "dashboard",
    path: "/dashboard",
    components: {
      default: Dashboard,
    },
    meta: {
      title: "Dashboard",
    },
    label: "Dashboard",
    closable: false,
    menu: true,
  },
  {
    name: "bufferstatus",
    path: "/bufferstatus",
    components: {
      default: BufferStat,
    },
    meta: {
      title: "BufferStatus",
    },
    label: "BufferStatus",
    closable: false,
    menu: false,
  },
  {
    name: "settings",
    path: "/settings",
    component: Settings,
    meta: {
      title: "Settings",
    },
  },
  {
    name: "login",
    path: "/login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    name: "forms",
    path: "/forms",
    components: {
      default: Forms,
    },
    meta: {},
  },
  {
    name: "user",
    path: "/user",
    components: {
      default: User,
    },
    meta: {
      title: "User profile",
    },
  },
  {
    name: "redirect",
    path: "/redirect",
    components: {
      default: Redirect,
    },
    meta: {},
  },
  {
    name: "actions",
    path: "/actions",
    components: {
      default: Actions,
    },
    meta: {
      title: "Actions",
    },
  },
  {
    name: "actions.design",
    path: "/actions/:id",
    components: {
      default: ActionDesign,
    },
    meta: {
      title: "Actions Design",
    },
  },
];

const router = new VueRouter({
  routes, // short for `routes: routes`,
  mode: "history",
});

router.afterEach((to, from) => {
  console.log("afterEach");
});

router.beforeEach(async (to, from, next) => {
  //console.log("beforeeach", to, from);
  let isAuthenticated =
    userService.user != null && userService.user.isAuthenticated == true;
  if (
    !isAuthenticated &&
    to.name !== "settings" &&
    to.name !== "login" &&
    to.path !== "/redirect"
  )  
   {
    localStorage.setItem('redirectUrl', to.fullPath);
    next({ name: "login" }); 
  }
  else {
    next();
  }
});

export default router;
