<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <!-- Edit Dialog -->
        <v-dialog class="ips-dialog" v-model="dialog" hide-overlay persistent>
          <v-card>
            <v-toolbar dark color="grey darken-4">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="save">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <flow-editor v-model="editingItem" :value="editingItem" :new-open-editor="true"/>
          </v-card>
        </v-dialog>

        <v-card class="px-8 py-4 mb-4">
          <v-card-title class="px-0 pb-0 pt-2">
            <h1 class=" fw-normal h3 text-center">
              {{ flow.name }}
              <v-btn class="ml-4" depressed outlined rounded color="primary" @click="edit(flow)">Edit</v-btn>
              <v-btn class="ml-4 right" depressed rounded color="primary" @click="run(flow)"
                     v-if="flow.trigger && flow.trigger.isScheduleEnabled">
                Run now <v-icon> mdi-play</v-icon>
              </v-btn>
            </h1>
            <v-spacer></v-spacer>
            <v-menu offset-y bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed rounded icon color="blue-grey darken-1" v-bind="attrs" v-on="on" left>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportFlow(flow)">
                  <v-list-item-title>
                    <v-icon>mdi-file-export</v-icon>Export
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportFlowWithDestination(flow)">
                  <v-list-item-title>
                    <v-icon>mdi-file-export</v-icon>
                    Export With Destinations
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col v-for="(key, index) in flowPropertiesKeys" :key="index">
              <div class="ips-subtitle">{{flowPropertiesKeys[key]??key}}</div>{{flowProperties[key]}}
            </v-col>
            <v-col v-if="webHookUrlToPrint">
              <div class="ips-subtitle">WebHookUrl</div>
              <div style="display:flex;">
                <div class="text-truncate" style="max-width:270px;">
                  {{ webHookUrl }}
                </div>
                <div style="margin-top: -7px;">
                  <v-btn icon @click="copyToClipboard(webHookUrl)">
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <!--<properties :data="flow" :map="propertiesOptions.map" :exclude="propertiesOptions.exclude"></properties>-->
          <v-divider class="my-4"></v-divider>
          
          <v-row class="mt-5">
            <v-col cols="4">
              <v-row>
                <v-col cols="12">
                  <span class="h4 fw-medium d-block">Last Hour Statistics</span>
                  <span class="fw-normal h5 mt-2 font-sm">Updated at {{ currentHour }}</span>
                </v-col>
                <v-col cols="6">
                  <div class="ips-chart-main">
                    <span class="h4 d-block text-uppercase fw-medium mb-2">Success</span>
                    <span class="h2 fw-bold ips-badge ips-badge-success">{{ statusMessagesByLastHour.success }}</span>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="ips-chart-sub mb-4">
                    <span class="h5 d-block text-uppercase fw-medium mb-2">Pending</span>
                    <span class="fw-bold h5 ips-badge ips-badge-pending"> {{ statusMessagesByLastHour.pending }}</span>
                  </div>
                  <div class="ips-chart-sub">
                    <span class="h5 d-block text-uppercase fw-medium mb-2">Error</span>
                    <span class="fw-bold h5 ips-badge ips-badge-error"> {{ statusMessagesByLastHour.error }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <span class="mb-2">Message Status over the Last 12 Hours 
                <v-btn class="ml-4 mb-2" small depressed rounded  @click="refreshGraphics('24HOURS')"><v-icon>mdi-reload</v-icon></v-btn>
              </span>
              <BarChart :height="50"  :key="componentKey" :chartData="chartData" :eventOnClickEmit="last12HourOnClickEmitEventName" @last12HourOnClickEmit="last12HourOnClickEmit" />
            </v-col>
            <v-col cols="4">
              <span class="mb-2">Error Message over the last week
                <v-btn class="ml-4 mb-1" small depressed rounded  @click="refreshGraphics('7DAYS')"><v-icon>mdi-reload</v-icon></v-btn>
              </span>
              <BarChart :height="50" :key="weekChartComponentKey" :chartData="errorOfWeekChartData" :eventOnClickEmit="lastWeekOnClickEmitEventName" @lastWeekOnClickEmit="lastErrorWeekOnClickEmit" />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-2">
          <v-tabs fixed-tabs class="py-2" v-model="tab" tabindex="0" center-active @change="setQueryParamFilter">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="completed">Completed Messagges</v-tab>
            <v-tab key="queue">Queue Messagges</v-tab> 
            <v-tab key="logs">Logs</v-tab>
          </v-tabs>
          <v-tabs-items class="vw-100" v-model="tab">
            <v-tab-item key="completed" :eager="true">
              <queue ref="completedRef" :inProgressQueue=false :flowId="flowId" :correlationPath="correlationPath" :correlation-priority-path="correlationPriorityPath" @search-logs="searchLogs"></queue>
            </v-tab-item>
            <v-tab-item key="queue" :eager="true">
              <queue ref="queueRef" :inProgressQueue=true :flowId="flowId" :correlationPath="correlationPath" :correlation-priority-path="correlationPriorityPath" @search-logs="searchLogs"></queue>
            </v-tab-item>
            <v-tab-item key="logs" :eager="true" ref="input">
              <logs name="f" :flowId="flowId" ref="logs"></logs>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import queue from "../queue/queue.vue";
import logs from "../logs/logs.vue";
import properties from "../shared/PropertyView.vue";
import userService from "../../services/user";
import FlowEditor from "../shared/FlowEditor.vue";
import BarChart from "../charts/BarChart.vue";
import moment from "moment";
import {ref} from "vue";

export default {
  name: "DestinationsEDIT",
  components: {
    FlowEditor,
    logs: logs,
    queue: queue,
    properties: properties,
    BarChart
  },
  beforeMount: async function () {
    this.flowId = this.$route.params.id;
    let response = await this.apiCall(
      `/api/editableflows/${this.flowId}`,
      "GET",
      {},
      {}
    );
    //console.log(response.data.payload.content);
    if (response.status === 200) {
      this.flow = response.data.payload.content;
      this.correlationPath = this.flow.correlationJsonPath;
      this.correlationPriorityPath = this.flow.correlationPriorityPath;
      if (this.correlationPath == null || this.correlationPath === "") {
        this.propertiesOptions.exclude.push("correlationJsonPath");
      }
      if (this.correlationPriorityPath == null || this.correlationPriorityPath === "") {
        this.propertiesOptions.exclude.push("correlationPriorityPath");
      }

      this.flowProperties = this.flow;
      this.flowPropertiesKeys = Object.keys(this.flow);
      this.flowPropertiesKeys = this.flowPropertiesKeys.filter(item => !this.propertiesOptions.exclude.includes(item), this);
      if (this.flow.trigger.isWebHookEnabled) {
        this.webHookUrlToPrint = true;
        this.webHookUrl = this.getFlowWebHookUrl(this.flow.tenantId, this.flow.trigger.webHookConf.path);
      }
    }

    await this.analytics();
    await this.sevenDaysErrorAnalytics();
  },
  mounted : function () {
    window.addEventListener('popstate', this.handleBackButton);
    this.fetchDataStatusOfLastHour(); 
    this.interval = setInterval(async () => {
      if (!this.fetchingLastHourGraphics) {
        await this.fetchDataStatusOfLastHour();
      }
    }, 3000);

    const queryFilter = this.$route.query.filter;
    if(queryFilter) {
      const filtersJsonString = atob(this.$route.query.filter);
      const filtersDecoded = JSON.parse(filtersJsonString);
      this.tab = filtersDecoded["queueType"] == "tenant" ? 0 : 1;
    }

  },
  destroyed: function () {
    clearInterval(this.interval)
  },
  methods: {
    handleBackButton : function (event) {
      location.reload();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    weekErrorChartForceRender() {
      this.weekChartComponentKey += 1;
    },
    //TODO: Repeated function here and in flowView. Create a service for this
    save: async function (data) {
      this.showSaving("Flow is saving...");
      if (this.editingItem.isValid === false) {
        this.showError(this.editingItem.errorMessage)
      } else 
      {
        if (this.editingItem.destinations[0].settingsOverride != null && this.editingItem.destinations[0].settingsOverride.formatSettings != null &&
            this.editingItem.destinations[0].settingsOverride.formatSettings.mapping != null &&
            typeof this.editingItem.destinations[0].settingsOverride.formatSettings.mapping === "string") {
          try {
            this.editingItem.destinations[0].settingsOverride.formatSettings.mapping = JSON.parse(this.editingItem.destinations[0].settingsOverride.formatSettings.mapping);
          }
          catch (e) {}
        }
        delete this.editingItem.isValid;
        delete this.editingItem.errorMessage;
        await this.update();
      }
      //await this.reload();
    },
    exportFlow: async function (data) 
    {
      return await this.apiCallForDownload(data.name,`/api/editableflows/${data.id}/download`,"GET",{},{});
    },
    exportFlowWithDestination: async function (data) 
    {
      await this.apiCallForDownload(data.name,`/api/editableflows/${data.id}/download`,"GET",{},{});
      for (const d of data.destinations) {
        const name = d.destinationReferenced.name;
        await this.apiCallForDownload(name, `/api/destinations/${d.destinationReferenced.id}/download`, "GET", {}, {});
      }
    },
    fetchDataStatusOfLastHour : async function() {
      if (this.fetchingLastHourGraphics) return;
      this.fetchingLastHourGraphics = true;
      try {
        let response = await this.apiCallWithExtraHeaders(
        `/api/dashboard/${this.flowId}/LastMessageStatusByHour`,
        "GET",
        {},
        {},
        {
        "browser-datetime" :  moment().format()
        },
      );
      this.statusMessagesByLastHour = response.data;
      const now = new Date();
      const day = now.getDate().toString().padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const year = now.getFullYear();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentHour = `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
      } catch (error) {
        console.error(error)

      } finally {
        this.fetchingLastHourGraphics = false;
      }
    },
    searchLogs: async function (data) {
      this.tab = 2;

      this.logSearchQuery = { MessageId: data.messageId };

      this.$refs.logs.setSearch(this.logSearchQuery);
      //let that=this;
      //setTimeout(() => that.$refs.logs.setSearch(that.logSearchQuery) , 500);
    },

    last12HourOnClickEmit : function(clickedBarLabel) 
	  {
      let hours = clickedBarLabel.label.split(":");
      let hourConverted = parseInt(hours[0]);
      let currentDateStart = new Date();
      let currentDataEnd = new Date();
      if (hourConverted > currentDateStart.getHours()) {
        //console.log(hourConverted);
        currentDateStart.setDate(currentDateStart.getDate() - 1);
        currentDataEnd.setDate(currentDataEnd.getDate() - 1);
      }
      currentDateStart.setHours(hourConverted);
      currentDateStart.setMinutes(0);
      currentDateStart.setSeconds(0);
      currentDateStart.setMilliseconds(0);
      currentDataEnd.setHours(hourConverted + 1);
      currentDataEnd.setMinutes(0);
      currentDataEnd.setSeconds(0);
      currentDataEnd.setMilliseconds(0);
      let queryString = "";
      const status = clickedBarLabel.legend;
      let dateTofilter = [];
      let statuses = [];
      let fieldDate = "";
      let isQueueDataTable = false;
      if (status === "Success") {
        statuses.push("success");
        fieldDate = "LastElaborationDate";
        queryString = queryString + "$and:[{\"LastElaborationDate\":{ $gte: new Date(\"" + currentDateStart.toISOString() + "\")}},{\"LastElaborationDate\":{ $lte : new Date(\"" + currentDataEnd.toISOString() + "\")}},{ \"Status\": 2 }]"
      } else {
        isQueueDataTable = true;
        fieldDate = "IngressDate";
        statuses.push("new");
        statuses.push("processing");
        statuses.push("aborted");
        statuses.push("sent");
        queryString = queryString + "$and:[{\"IngressDate\":{ $gte: new Date(\"" + currentDateStart.toISOString() + "\")}},{\"IngressDate\":{ $lte : new Date(\"" + currentDataEnd.toISOString() + "\")}},{\"$or\": [{ \"Status\": 0 },{ \"Status\": 1 },{ \"Status\": 4 },{ \"Status\": 5 }]}]"
      }
      dateTofilter.push(moment(currentDateStart).format("DD/MM/YYYY HH:mm"));
      dateTofilter.push(moment(currentDataEnd).format("DD/MM/YYYY HH:mm"));
      if (isQueueDataTable)
      {
        this.$refs.queueRef.handleBarChartFilterSearch(queryString,dateTofilter,statuses,fieldDate);
        this.tab = 1;
      }
      else
      {
        this.$refs.completedRef.handleBarChartFilterSearch(queryString,dateTofilter,statuses,fieldDate);
        this.tab = 0;
      } 
    },

    lastErrorWeekOnClickEmit : function(clickedBarLabel) {
        const labelData = this.errorChartLabelValue[clickedBarLabel.label];
        const splitData = labelData.split("-");
        const currentDateStart = new Date(splitData[2], splitData[1] - 1, splitData[0]);
        const currentDataEnd = new Date(splitData[2], splitData[1] - 1, splitData[0]);
        currentDataEnd.setDate(currentDataEnd.getDate() + 1);
        let dateTofilter = [];
        let statuses = [];
        dateTofilter.push(moment(currentDateStart).format("DD/MM/YYYY HH:mm"));
        dateTofilter.push(moment(currentDataEnd).format("DD/MM/YYYY HH:mm"));
        statuses.push("error");
        const fieldDate = "LastElaborationDate";
        const queryString = "$and:[{\"LastElaborationDate\":{ $gte: new Date(\"" + currentDateStart.toISOString() + "\")}},{\"LastElaborationDate\":{ $lte : new Date(\"" + currentDataEnd.toISOString() + "\")}}, { \"Status\": 3 }]";
        this.$refs.completedRef.handleBarChartFilterSearch(queryString,dateTofilter,statuses,fieldDate);
    },
    edit: async function (data) {
      if (data.destinations && data.destinations.length > 0) {
        data.destinations.forEach((dest) => {
          delete dest.destinationReferenced;
        });
      }

      this.dialog = true;
      this.editingTitle = "Editing " + data.name;
      this.editingItem = data;
    },

    update: async function () {
      this.showSaving("Flow is saving...");
      let response = await this.apiCall(
        `/api/editableflows/${this.editingItem.id}`,
        "PUT",
       this.editingItem,
        {}
      );
      if (response.status == 200) {
        this.flow = response.data.payload.content;
        this.showSuccess("Flow updated");
      } else {
        this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
      }
    },

    reload: async function () {
      await this.reloadEgress(this.flow);
      await this.reloadScheduled(this.flow);
    },
    reloadScheduled: async function (data) {
      this.showSaving("Reloading Scheduler");
      let response = await this.schedulerCall(
        `/api/jobs/reload/${data.id}`,
        "POST",
        {},
        {}
      );
      this.showSuccess("Scheduler reloaded");
      this.dialog = false;
    },
    reloadEgress: async function (data) {
      this.showSaving("Reloading Egress");
      let response = await this.egressCall(
        `/api/status/reload/${data.id}`,
        "POST",
        {},
        {}
      );
      this.showSuccess("Egress reloaded");
      this.dialog = false;
    },

    run: async function (data) {
      //let tenantSsiId=localStorage.getItem("userTenant");
      let user = userService.user;
      let tenantSsiId = user.tenant;
      this.showSaving(
        "Starting job... the operation is syncrounous so it can take a little"
      );
      let response = await this.schedulerCall(
        `/api/jobs/${tenantSsiId}/${data.id}`,
        "POST",
        {},
        {}
      );

      if (response.status == 200 || response.status == 202) {
        this.showSuccess("Job executed");
      }
    },
    getFormattedData(messageStatusFlowCount) {
      return [messageStatusFlowCount];
    },
    analytics: async function() {
      let response = await this.apiCallWithExtraHeaders(
        `/api/dashboard/${this.flowId}/LastMessageStatusBy24Hours`,
        "GET",
        {},
        {},
        {
        "browser-datetime" :  moment().format()
        },
      );
      const success = [];
      const errors = [];
      const pending = [];
      const labels = [];

      for (let i = 12; i > 0; i--) {
        const nextHour = new Date();
        const localhour = new Date(nextHour);
        localhour.setHours(localhour.getHours() - i);
        nextHour.setUTCHours(nextHour.getUTCHours() - i);
        const z = nextHour.getUTCHours().toString();
        labels.push(localhour.getHours().toString() + ":00")
        success.push(response.data[z].success)
        pending.push(response.data[z].pending)
        errors.push(response.data[z].error)
        }
        const datasets = [
        {
              label: 'Success',
              backgroundColor: '#78cf11',
              stack: 'stack 1',
              data: success
            },
          {
            label: 'Pending',
            backgroundColor: '#ffb70a',
            stack: 'stack 1',
            data: pending
          }
        ]
        const chartData  = {};
        chartData.labels = labels;
        chartData.datasets = datasets;
        this.chartData = chartData;
        this.forceRerender();
    },
    sevenDaysErrorAnalytics: async function (){
      let response = await this.apiCallWithExtraHeaders(
        `/api/dashboard/${this.flowId}/LastMessageStatusByLastWeek`,
        "GET",
        {},
        {},
        {"browser-datetime" :  moment().format()}
      );

      const errors = [];
      const labels = [];

      for (let i = 7; i >= 0; i--) {
        const currentDay = new Date();
        currentDay.setDate(currentDay.getDate() - i);
        const z = currentDay.getDate().toString();
        labels.push(response.data[z].range)
        errors.push(response.data[z].error)
        }
        const datasets = [
        {
              label: 'Error',
              backgroundColor: '#f33950',
              stack: 'stack 1',
              data: errors
            }
        ]
        const chartData  = {};
        let labelsWithoutYears = labels.map((label) => {
          let newLabel = label.split("-")[0] + "-" + label.split("-")[1];
          this.errorChartLabelValue[newLabel] = label;
          return newLabel;
        });
        chartData.labels = labelsWithoutYears;
        chartData.datasets = datasets;
        this.errorOfWeekChartData = chartData;
        this.weekErrorChartForceRender();
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
          .then(() => {
            this.showSuccess('Copied to clipboard');
          })
          .catch(() => {
            this.showError('Failed to copy to clipboard');
          });
    },
    refreshGraphics: async function (prefix) 
    {
      let response = await this.apiCall(
        `/api/dashboard/${this.flowId}/InvalidateCache`,
        "GET",
        {},
        {"prefix" : prefix}
      );
      if (response.status == 200) {
        if (prefix === "24HOURS") {
          await this.analytics();
        } else {
          await this.sevenDaysErrorAnalytics();
        }
      }
    },
    setQueryParamFilter: function (tabId) {
      switch(tabId){
        case 0:
        if (this.$refs.completedRef) {
          this.$refs.completedRef.updateURLWithFiltersEncoded();
        }
          break;
        case 1:
          if (this.$refs.queueRef) {
            this.$refs.queueRef.updateURLWithFiltersEncoded();
          }
          break;
        default:
          history.pushState(null, '', `${window.location.pathname}`);
          break;
      }
    }
  },
  data: () => ({
    flowId: "",
    correlationPath: "",
    correlationPriorityPath: "",
    flow: {},
    tab: null,
    needLogSearch: false,
    logSearchQuery: {},
    flowProperties: [],
    flowPropertiesKeys: [],
    webHookUrlToPrint: false,
    webHookUrl: "",
    propertiesOptions: {
      map: {
      },
      exclude: [
        "name",
        "destinations",
        "trigger",
        "extraProperties",
        "options",
        "embed",
        "computedFields",
        "pipeline",
        "modifiedOn",
        "modifiedBy",
        "createdOn",
        "createdBy",
        "deletedOn",
        "deletedBy",
      ],
    },
    messageStatusFlowCount: {},
    dynamicFields: [],
    headers: [],
    editingItem: {},
    editingTitle: "",
    dialog: false,
    TwentyFourHoursStatusCount : {},
    chartData: {},
    errorOfWeekChartData: {},
    componentKey: 0,
    statusMessagesByLastHour: {},
    currentHour: "",
    weekChartComponentKey: 0,
    last12HourOnClickEmitEventName: "last12HourOnClickEmit",
    lastWeekOnClickEmitEventName : "lastWeekOnClickEmit",
    fetchingLastHourGraphics : false,
    errorChartLabelValue: []
  }),
};
</script>

<style scoped>
.menu-with-border .v-menu__content {
  border: 2px solid #3f51b5 !important; /* Forza l'applicazione del bordo */
  border-radius: 8px !important; /* Forza l'applicazione del bordo arrotondato */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25) !important; /* Forza l'applicazione dell'ombra */
}
</style>
